// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Classes
import { PlayerFieldPosition } from "../enums/player-field-position.enum";

@Pipe({
    name: "fieldPosition"
})
export class FieldPositionPipe implements PipeTransform {

    transform(value: PlayerFieldPosition): string {
        switch (value) {
            case PlayerFieldPosition.goal:
                return "Goal";
            case PlayerFieldPosition.defense:
                return "Defense";
            case PlayerFieldPosition.midfield:
                return "Midfield";
            case PlayerFieldPosition.offense:
                return "Offense";
            case PlayerFieldPosition.coach:
                return "Coach";
            case PlayerFieldPosition.coachingStaff:
                return "Coaching staff";
            case PlayerFieldPosition.staff:
                return "Staff";
            default:
                return "";
        }
    }
}
